import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
      this.state = { hasError: false, error: null };
  }
  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }
  componentDidCatch(error, errorInfo) {
    console.log("Error caught in ErrorBoundary:", error, errorInfo);
  }
  componentDidUpdate(prevProps) {
    if (this.state.hasError && this.props.children !== prevProps.children) {
      this.setState({ hasError: false, error: null });
    }
  }
  render() {
    if (this.state.hasError) {
      return <div>Error: Something went wrong</div>;
    }
    return this.props.children;
  }
}
export default ErrorBoundary;
