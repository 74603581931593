import { Tooltip } from "impact-ui";
import { TrendingUp } from "@mui/icons-material";
import "./invKpiStyle.css";
import { useEffect, useState, useMemo } from "react";
import ScrollContainer from "./ScrollContainer";
import { getMultipleModelsData } from "../../action";
import CustomGridContainer from "./GridContainer";
import { useSelector } from "react-redux";

export default function InvKPICards({ data }) {
  const { apikey, width = 225 } = data;
  const [cardsData, setCardsData] = useState([]);
  const { dependentInfo } = useSelector((state) => state.home);
  const expand = dependentInfo[data["expandDependentKey"]] || false;
  useEffect(async () => {
    const res = await getMultipleModelsData({ model_names: [apikey] });
    setCardsData(res[apikey]);
  }, [apikey]);

  const invCards = useMemo(() => {
    return cardsData.map((item) => {
      const cardWidth = item.width || width;
      return (
        <div className="inv-kpi-card" style={{ width: `${cardWidth}px` }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              marginBottom: "20px",
            }}
          >
            {renderIcon(item.iconColor || "")}
            <Tooltip
              text={item.tooltip}
              disabled={item.tooltip ? false : true}
              placement={"bottom"}
              isPortal={true}
              zIndex={5}
            >
              <p
                className="inv-kpi-card-header"
                style={{ maxWidth: `${cardWidth * 0.7}px` }}
              >
                {item.header}
              </p>
            </Tooltip>
          </div>
          {renderContent(item.content || "")}
        </div>
      );
    });
  }, [cardsData]);

  return (
    <div style={{}}>
      {expand ? (
        <CustomGridContainer>
          <>{invCards}</>
        </CustomGridContainer>
      ) : (
        <ScrollContainer>
          <>{invCards}</>
        </ScrollContainer>
      )}
    </div>
  );
}
function renderContent(contentStr = "") {
  const content = contentStr.split("|");
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "100px",
        marginBottom: "5px",
      }}
    >
      {content.map((item) => {
        const dataSet = item.split(",");
        return (
          <div>
            <p
              style={{
                fontSize: "11px",
                lineHeight: "16.5px",
                letterSpacing: "1%",
                fontWeight: 400,
                color: "#808894",
              }}
            >
              {dataSet[0]}
            </p>
            <p
              style={{
                fontSize: "14px",
                lineHeight: "21px",
                letterSpacing: "1%",
                fontWeight: 500,
                color: dataSet[2],
              }}
            >
              {dataSet[1]}
            </p>
          </div>
        );
      })}
    </div>
  );
}
function renderIcon(cellValue) {
  const dataSet = cellValue.split("|");
  return (
    <div
      className="inv-kpi-card-icon"
      style={{ color: dataSet[0], backgroundColor: dataSet[1] }}
    >
      <TrendingUp sx={{ fontSize: "medium" }} />
    </div>
  );
}
