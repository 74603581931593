import React from "react";
// eslint-disable-next-line import/no-cycle
import ChildNode from "./ChildNode";

function MidComp({ data, parentId }) {
	return (
		<div>
			<ChildNode node={data} parentId={parentId} />
		</div>
	);
}

export default React.memo(MidComp);
