import { useRef } from "react";

export default function ScrollContainer(props) {
  const childrenWrapperRef = useRef(null);
  return (
    <div>
      <div
        style={{
          display: "flex",
          overflowX: "auto",
          gap: "24px",
          padding:"10px 10px 16px 10px"
        }}
        ref={childrenWrapperRef}
      >
        {props.children}
      </div>
    </div>
  );
}
