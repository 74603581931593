export const lineChartOptions = ({
  height,
  title,
  yaxis,
  xaxis,
  showLegend,
  legend,
  marker,
  dataLabels,
  data,
  backgroundColor,
  hideBg = false,
  scrollable={}
}) => {
  return {
    chart: {
      type: "line",
      height: height,
      spacing: [20, 10, 10, 5],
      backgroundColor: hideBg ? null : backgroundColor || "#F5F8FA",
      plotBackgroundColor: "#ffffff",
      ignoreHiddenSeries: false,
      scrollablePlotArea: scrollable
    },
    title: {
      text: title,
    },
    yAxis: Array.isArray(yaxis)
      ? yaxis
      : yaxis.dualAxis ? [{
          title: {
            text: yaxis.title,
            style: {
              color: "#44677b",
              fontSize: "0.7rem",
              fontWeight: "600",
            },
          },
          gridLineWidth: yaxis.gridLineWidth === false ? false : true,
          labels: {
            enabled: yaxis.labels === false ? false : true,
            style: {
              color: "#44677b",
              fontSize: "0.6rem",
            },
          },
          tickAmount: yaxis.tickAmount,
          gridLineColor: "#e9e9e9",
          categories: yaxis.categories
        },
        {
          title: {
            text: yaxis.title,
            style: {
              color: "#44677b",
              fontSize: "0.7rem",
              fontWeight: "600",
            },
          },
          gridLineWidth: yaxis.gridLineWidth === false ? false : true,
          labels: {
            enabled: yaxis.labels === false ? false : true,
            style: {
              color: "#44677b",
              fontSize: "0.6rem",
            },
          },
          tickAmount: yaxis.tickAmount,
          gridLineColor: "#e9e9e9",
          categories: yaxis.categories,
          opposite: true
        }] : {
          title: {
            text: yaxis.title,
            style: {
              color: "#44677b",
              fontSize: "0.7rem",
              fontWeight: "600",
            },
          },
          gridLineWidth: yaxis.gridLineWidth === false ? false : true,
          labels: {
            enabled: yaxis.labels === false ? false : true,
            style: {
              color: "#44677b",
              fontSize: "0.6rem",
            },
          },
          tickAmount: yaxis.tickAmount,
          gridLineColor: "#e9e9e9",
          categories: yaxis.categories
        },
    xAxis: {
      title: {
        text: xaxis.titleLabel || xaxis.title,
        style: {
          color: "#44677b",
          fontSize: "0.7rem",
          fontWeight: "500",
        },
      },
      accessibility: {
        rangeDescription: xaxis.title,
      },
      lineWidth: xaxis.lineWidth === false ? false : true,
      labels: {
        enabled: xaxis.labels === false ? false : true,
        style: {
          color: "#44677b",
          fontSize: "0.6rem"
        },
        overflow: xaxis.overflow||'justify',
        scrollbar: {
          enabled:true
        },
        autoRotation: false,
        formatter: function () {
          if (this.value === "-") {
            return "";
          }
          return this.value;
        },
      },
      categories: xaxis.categories,
      plotLines: xaxis.plotLines,
      plotBands: xaxis.plotBands,
    },
    legend: {
      enabled: showLegend,
      layout: "horizontal",
      itemStyle: {
        color: "#44677b",
        fontSize: "0.7rem",
        fontWeight: "600",
      },
      itemCheckboxStyle: {
        position: "absolute",
        width: "12px",
        height: "12px",
      },
      padding: 0,
      itemMarginTop: 4,
      itemMarginBottom: 2,
      ...legend,
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      line: {
        dataLabels: {
          enabled: dataLabels || false,
        },
      },
      series: {
        marker: {
          enabled: marker || false,
        },
        states: {
          hover: {
            enabled: true,
            radius: 1,
          },
        },
        lineWidth: 1.5,
      },
    },
    series: data,
    credits: {
      enabled: false,
    },
  };
};
