export default function CustomGridContainer(props) {
  return (
    <div
      style={{
        display: "flex",
        gap: "24px",
        flexWrap: "wrap",
        padding: "10px 10px 16px 10px",
      }}
    >
      {props.children}
    </div>
  );
}
