import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { LicenseManager } from "ag-grid-enterprise";
import React, { useRef, useEffect, useState } from "react";
import "./DataTable.scss";
import { StyledTableContainer } from "./Table-Style";

LicenseManager.setLicenseKey(
	"CompanyName=Impact Analytics,LicensedGroup=31Jan22 Purchase,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-025014,ExpiryDate=31_January_2023_[v2]_MTY3NTEyMzIwMDAwMA==e4f58ef1fe10261cf66aa1e5a5cb2da6"
);

function Table(props) {
	const gridRef = useRef();
	const [firstRefresh, setFirstRefresh] = useState(true);
	const [rowData, setRowData] = useState(props?.rowData || []);
	const [gridApi, setGridApi] = useState(null);

	useEffect(() => {
		if (props?.isClickBasedOnParent && gridApi) {
			if (firstRefresh) {
				gridApi.setRowData([...rowData]);
				setFirstRefresh(false);
			} else {
				gridApi.refreshCells({ force: true });
			}
		}
	}, [rowData]);

	const onGridReady = (params) => {
		setGridApi(params.api);
	};

	const handleFirstDataRendered = (event) => {
		props.sizeToFit && event.api.sizeColumnsToFit();
		event.columnApi.autoSizeAllColumns();
	};

	const gridSizeChanged = (event) => {
		props.sizeToFit && event.api.sizeColumnsToFit();
		event.columnApi.autoSizeAllColumns();
	};

	// const exportGrid = (type) => {
	// 	switch (type) {
	// 		case "xlxs":
	// 			gridRef.current.api.exportDataAsExcel({
	// 				processCellCallback: (params) => {
	// 					if (params?.value === null) return "-";
	// 					if (
	// 						params?.column?.colDef?.isDownloadSpecialCharFormatter === true
	// 					) {
	// 						return specialCharValueFormatter({ value: params?.value });
	// 					}
	// 					return params?.value;
	// 				},
	// 			});
	// 			break;
	// 		case "csv":
	// 			gridRef.current.api.exportDataAsCsv({
	// 				processCellCallback: (params) => {
	// 					if (params?.value === null) return "-";
	// 					if (
	// 						params?.column?.colDef?.isDownloadSpecialCharFormatter === true
	// 					) {
	// 						return specialCharValueFormatter({ value: params?.value });
	// 					}
	// 					return params?.value;
	// 				},
	// 			});
	// 			break;
	// 		default:
	// 			break;
	// 	}
	// };

	// Export grid to excel
	// useEffect(() => {
	// 	if (exportType?.exportGrid) {
	// 		exportGrid(exportType?.type);
	// 	}
	// }, [exportType]);

	return (
		<div className="ag-theme-balham" style={{ height: "100%", width: "100%" }}>
			<StyledTableContainer className="ag-theme-alpine">
				<AgGridReact
					ref={gridRef}
					rowHeight="32"
					headerHeight="35"
					suppressMenuHide={false}
					animateRows
					rowData={props?.rowData || []}
					onGridReady={onGridReady}
					rowSelection="multiple"
					groupSelectsChildren
					onFirstDataRendered={handleFirstDataRendered}
					onGridSizeChanged={gridSizeChanged}
					autoGroupColumnDef={{
						pinned: "left",
					}}
					getRowClass={() => "lighter-font-row"}
					{...props}
				/>
			</StyledTableContainer>
		</div>
	);
}

export default Table;
