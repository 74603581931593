import React from "react";
import moment from "moment";
import numeral from "numeral";
// import _ from "lodash";

const ranges = [
	{ divider: 1e9, suffix: "B" },
	{ divider: 1e6, suffix: "M" },
	{ divider: 1e3, suffix: "k" },
];

export const fallbackFormatter = function (params) {
	const { value, node } = params;
	if (value === 0) return value;
	if (node && node.group && value === undefined) return "";
	if (!value) return "-";
	return value;
};

export function formatThousands(n) {
	if (!n || n === 0) return 0;
	for (var i = 0; i < ranges.length; i++) {
		if (n >= ranges[i].divider) {
			let newNumber = (n / ranges[i].divider).toFixed(2);
			let roundOffNumber = Math.abs(newNumber);
			return roundOffNumber.toString() + ranges[i].suffix;
		}
	}
	return n;
}

export const dateFormatter = function (params) {
	return moment(params.value).format("MM-DD-YYYY");
};

export const dateRangeFormatter = function (params) {
	if (params && params.start_of_week && params.end_of_week) {
		let { start_of_week, end_of_week } = params;

		return start_of_week && end_of_week
			? moment(start_of_week).format("MM/DD/YYYY") +
					" - " +
					moment(end_of_week).format("MM/DD/YYYY")
			: "-";
	}
	return params.value ? `W${params.value}` : "-";
};

export const toPercentage = function (params) {
	if (params.node && params.node.group && params.value === undefined)
		return "";
	return params.value || params.value === 0
		? numeral(params.value / 100).format("(0%)")
		: "-";
};

export const toPercWithOneDecimal = function (params) {
	if (params.value || params.value === 0) {
		return numeral(params.value / 100).format("(0.0%)");
	} else if (params.node && params.node.group && params.value === undefined)
		return "";
	else {
		return "-";
	}
};

export const toPercentageMultipliedByHundred = function (params) {
	if (params.node && params.node.group && params.value === undefined)
		return "";
	return params.value || params.value === 0
		? numeral(params.value).format("(0%)")
		: "-";
};

export const toPercentageWithDecimal = function (params) {
	if (params.node && params.node.group && params.value === undefined)
		return "";
	if (params.value === "-") {
		return "-";
	}
	return params.value || params.value === 0
		? numeral(params.value / 100).format("(0.00%)")
		: "-";
};

export const toFixedToTwo = function (params) {
	if (params.node && params.node.group && params.value === undefined)
		return "";
	return params.value || params.value === 0
		? numeral(params.value / 100).format("(0.00)")
		: "-";
};

export const toDollar = function (params) {
	if (params.node && params.node.group && params.value === undefined)
		return "";
	if (params.value || params.value === 0) {
		let val = numeral(params.value).format("($0,00)");
		return val === "0$" ? "$0" : val;
	}

	return "-";
};

export const toDollarWithDecimal = function (params) {
	if (params.node && params.node.group && params.value === undefined)
		return "";
	return params.value || params.value === 0
		? numeral(params.value).format("($0,0.00)")
		: "-";
};

export const toDollarWithDecimalFormatted = function (params) {
	if (params.node && params.node.group && params.value === undefined)
		return "";
	return params.value || params.value === 0
		? numeral(params.value).format("($0.00 a)")
		: "-";
};

export const toUnit = function (params) {
	if (params.value === "-") {
		return "-";
	}
	if (params.node && params.node.group && params.value === undefined)
		return "";
	return params.value || params.value === 0
		? numeral(params.value).format("(0,0)")
		: "-";
};

export const toUnitFormatted = function (params) {
	if (params.value === "-") {
		return "-";
	}
	if (params.node && params.node.group && params.value === undefined)
		return "";
	return params.value || params.value === 0
		? numeral(params.value).format("(0,0 a)")
		: "-";
};

export const toUnitNoSeparator = function (params) {
	return params.value || params.value === 0 ? Math.round(params.value) : "-";
};

export const decimalFormatter = function (params) {
	if (params.value || params.value === 0) {
		return numeral(params.value).format("(0.00)");
	} else if (params.node && params.node.group && params.value === undefined)
		return "";
	else {
		return "-";
	}
};
export const oneDecimalFormatter = function (params) {
	if (params.node && params.node.group && params.value === undefined)
		return "";
	return params.value || params.value === 0
		? numeral(params.value).format("(0.0)")
		: "-";
};
export const toDollarWithlDraft = function (params) {
	if (params.value || params.value === 0) {
		return numeral(params.value).format("($0,00)");
	} else if (params.node && params.node.group && params.value === undefined)
		return "";
	else {
		return "-";
	}
};
export const toPercentageWithDecimalDraft = function (params) {
	if (params.value || params.value === 0) {
		return numeral(params.value / 100).format("(0.00%)");
	} else if (params.node && params.node.group && params.value === undefined)
		return "";
	else {
		return "-";
	}
};
export const toPercentageInto100WithDecimalDraft = function (params) {
	if (params.value === 0) {
		return numeral(params.value / 100).format("(0.00%)");
	} else if (params.value) {
		return numeral(params.value).format("(0.00%)");
	} else if (params.node && params.node.group && params.value === undefined)
		return "";
	else {
		return "-";
	}
};
export const toUnitDraft = function (params) {
	if (params.value || params.value === 0) {
		return numeral(params.value).format("(0,0)");
	} else if (params.node && params.node.group && params.value === undefined)
		return "";
	else {
		return "-";
	}
};
export const toFixedToTwoDraft = function (params) {
	if (params.value || params.value === 0) {
		return numeral(params.value / 100).format("(0.00)");
	} else if (params.node && params.node.group && params.value === undefined)
		return "";
	else {
		return "-";
	}
};

export const weekFormatter = function (params, agg) {
	if (params.value || params.value === 0) {
		return `W${params.value}`;
	} else {
		return "-";
	}
};

export const toFixedToTwoSimple = function (params) {
	if (params.node && params.node.group && params.value === undefined)
		return "";
	return params.value || params.value === 0
		? numeral(params.value).format("(0.00)")
		: "-";
};

export const scenarioFormatter = function ({ value }) {
	if (value === undefined) return "";
	if (!value) return "-";
	if (value === "current_discount") {
		return "Last Finalized";
	}
	if (value === "ia_recommended") {
		return "IA Recommended";
	}
	if (value === "finalized") {
		return "Finalized";
	}
	return value;
};

export const metricsFormatter = function ({ value }) {
	if (value === undefined) return "";
	if (!value) return "-";
	if (
		value === "gross_margin_current_discount" ||
		value === "gross_margin_ia_recommended" ||
		value === "gross_margin_finalized"
	) {
		return "GM $";
	}
	if (
		value === "sales_units_current_discount" ||
		value === "sales_units_ia_recommended" ||
		value === "sales_units_finalized"
	) {
		return "Sales Units";
	}
	if (
		value === "sales_dollars_current_discount" ||
		value === "sales_dollars_ia_recommended" ||
		value === "sales_dollars_finalized"
	) {
		return "Sales $";
	}
	if (
		value === "aur_current_discount" ||
		value === "aur_ia_recommended" ||
		value === "aur_finalized"
	) {
		return "AUR";
	}
	if (
		value === "gross_margin_percent_current_discount" ||
		value === "gross_margin_percent_ia_recommended" ||
		value === "gross_margin_percent_finalized"
	) {
		return "GM %";
	}
	if (
		value === "store_oh_current_discount" ||
		value === "store_oh_ia_recommended" ||
		value === "store_oh_finalized"
	) {
		return "Store OH";
	}
	return value;
};
export const statusCellStyling = function ({ value, download }) {
	if (download) {
		return value ? value : "-";
	}
	switch (value) {
		case "rejected":
			return (
				<span style={{ textTransform: "capitalize" }}>
					<i
						className="fa fa-ban fa-lg"
						aria-hidden="true"
						style={{ color: "#D84039" }}
					></i>{" "}
					{value} &nbsp; &nbsp;{" "}
					{/* <i
            className="fa fa-commenting fa-lg"
            aria-hidden="true"
            style={{ cursor: 'pointer' }}></i> */}
				</span>
			);
		case "draft":
			return (
				<span style={{ textTransform: "capitalize" }}>
					<i
						className="fa fa-edit fa-lg"
						aria-hidden="true"
						style={{ color: "#44677B" }}
					></i>{" "}
					{value} &nbsp; &nbsp;{" "}
				</span>
			);
		case "pending":
			return (
				<span style={{ textTransform: "capitalize" }}>
					<i
						className="fa fa-exclamation-circle fa-lg"
						style={{ color: "#E0D337" }}
						aria-hidden="true"
					></i>{" "}
					{value}
				</span>
			);
		case "approved":
			return (
				<span style={{ textTransform: "capitalize" }}>
					<i
						className="fa fa-check-circle fa-lg"
						aria-hidden="true"
						style={{ color: "#59C770" }}
					></i>{" "}
					{value}
				</span>
			);

		default:
			return "";
	}
};

export const checkForConsecutiveNumbers = (arr) => {
	const differenceAry = arr.slice(1).map(function (n, i) {
		return n - arr[i];
	});
	return differenceAry.every((value) => value == 1);
};

export const kFormatter = (num) => {
	return Math.abs(num) > 999
		? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
		: Math.sign(num) * Math.abs(num);
};

export const kFormatterNoDecimal = (num) => {
	return Math.abs(num) > 999
		? Math.sign(num) * (Math.abs(num) / 1000).toFixed(0) + "k"
		: (Math.sign(num) * Math.abs(num)).toFixed(0);
};

export const kFormatterOneDecimal = (num) => {
	return Math.abs(num) > 999
		? Math.sign(num) * (Math.abs(num) / 1000).toFixed(0) + "k"
		: (Math.sign(num) * Math.abs(num)).toFixed(1);
};

export const dollarFormatterTwoDecimal = (num) => {
	return "$" + (Math.sign(num) * Math.abs(num)).toFixed(2);
};

export const epocFormatter = function ({ value, node }) {
	if (node && node.group && value === undefined) return "";
	if (!value) return "-";
	if (value === "percent") return "% OFF";
	if (value === "fixed_price") {
		return "PP";
	}
	return value;
};

export const sortObjectArrayAscending = (array, key, type) => {
	if (type === "text") {
		array.sort((a, b) => {
			return a[key].toLowerCase() < b[key].toLowerCase()
				? -1
				: a[key].toLowerCase() > b[key].toLowerCase()
				? 1
				: 0;
		});
	} else if (type === "date") {
		array.sort((a, b) => {
			if (a[key] === "TBD" || !a[key]) return 1;
			if (b[key] === "TBD" || !b[key]) return -1;
			return new Date(a[key]) < new Date(b[key])
				? -1
				: new Date(a[key]) > new Date(b[key])
				? 1
				: 0;
		});
	} else {
		array.sort((a, b) => {
			return a[key] - b[key];
		});
	}
	return array;
};

export const sortObjectArrayDescending = (array, key, type) => {
	if (type === "text") {
		array.sort((a, b) => {
			return a[key].toLowerCase() > b[key].toLowerCase()
				? -1
				: a[key].toLowerCase() < b[key].toLowerCase()
				? 1
				: 0;
		});
	} else if (type === "date") {
		array.sort((a, b) => {
			if (a[key] === "TBD" || !a[key]) return 1;
			if (b[key] === "TBD" || !b[key]) return -1;
			return new Date(a[key]) > new Date(b[key])
				? -1
				: new Date(a[key]) < new Date(b[key])
				? 1
				: 0;
		});
	} else {
		array.sort((a, b) => {
			return b[key] - a[key];
		});
	}
	return array;
};

export const clearanceEventFormatter = function ({ value, node }) {
	if (node && node.group && value === undefined) return "";
	if (!value) return "-";
	if (value === "first") return "First";
	if (value === "further") return "Further";
	if (value === "end_of_life") return "End Of Life";
	if (value === "total") return "Total";
	return value;
};

export const performanceFormatter = function ({ value, node }) {
	if (node && node.group && value === undefined) return "";
	if (!value) return "-";
	if (value === "on_track") return "On Track";
	if (value === "under_achieving") return "Under achieving";
	if (value === "over_achieving") return "Over achieving";
	return value;
};

export const doorbusterFormatter = function ({ value, node }) {
	if (value === true || value === "true" || value === "1" || value === 1)
		return "Yes";
	if (value === false || value === "false" || value === "0" || value === 0)
		return "No";
	if (node && node.group && value === undefined) return "";
	return "-";
};

export const convertToInternationalCurrencySystem = (labelValue) => {
	if (labelValue === "-") {
		return "-";
	}

	// Nine Zeroes for Billions
	return Math.abs(Number(labelValue)) >= 1.0e9
		? "$" + (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + " Bn"
		: // Six Zeroes for Millions
		Math.abs(Number(labelValue)) >= 1.0e6
		? "$" + (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + " Mn"
		: // Three Zeroes for Thousands
		Math.abs(Number(labelValue)) >= 1.0e3
		? "$" + (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + "K"
		: "$" + Math.abs(Number(labelValue)).toFixed(2);
};

export const truncate2Decimal = (num) => {
	return Math.floor(parseFloat(num) * 100) / 100;
};


export const checkResponseBool = (value) => {
  return value === "TRUE";
};