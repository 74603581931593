export const donutChartOptions = ({
  title,
  subtitle,
  totalValue,
  valueSuffix,
  colors,
  name,
  data,
  dataLabels,
  innerSize,
  legends
}) => {
  let subtitleHTML = `<div class='donut-subtitle' style="text-align:center">
  <span style="font-size: 18px;">${subtitle}</span>
    <br>
    <br>
    <span style="font-size: 16px; color:black">
        <b> ${totalValue}</b>
    </span>
  </div>`;

  return {
    title: {
      text: title,
    },
    subtitle: {
      useHTML: true,
      text: subtitleHTML,
      floating: true,
      verticalAlign: "middle",
      y: 30,
    },

    legend: {
      enabled: true,
    },
    tooltip: {
      valueDecimals: 2,
      valueSuffix: valueSuffix,
    },

    plotOptions: {
      series: {
        borderWidth: 0,
        colorByPoint: true,
        type: "pie",
        size: "90%",
        innerSize: innerSize || "80%",
        dataLabels: {
          enabled: dataLabels,
          crop: false,
          distance: "-2%",
          style: {
            fontWeight: "500",
            fontSize: "12px",
          },
          connectorWidth: 0,
        },
      },
      pie: {
        showInLegend: legends || false,
      },
    },
    colors: colors,
    series: [
      {
        type: "pie",
        name: name,
        data: data,
      },
    ],
  };
};
