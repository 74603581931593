import React, { useState } from "react";
import Popper from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import IconButton from "@mui/material/IconButton";
import PerfAnalyzer from "./perf_analyzer.png";
import "./style.css";

function ImagePopup(props) {
	const [anchorEl, setAnchorEl] = useState(null);
	const [open, setOpen] = useState(false);
	const [placement, setPlacement] = useState("right-end");

	const onImageAction = (e) => {
		setOpen(!open);
		setAnchorEl(e.currentTarget);
		setPlacement(placement);
	};

	return (
		<div className="popup-image-container">
			<span className="legend-text" onClick={onImageAction}>
				(Click here for legends)
			</span>
			<Popper
				open={open}
				anchorEl={anchorEl}
				transition
				className="popper-style">
				{({ TransitionProps }) => (
					<Fade {...TransitionProps} timeout={350}>
						<Paper>
							<IconButton
								className="cancel-button-wrapper"
								onClick={() => setOpen(false)}>
								<CancelOutlinedIcon />
							</IconButton>
							<img
								width="100%"
								className="popup-image"
								src={PerfAnalyzer}
								alt=""
							/>
						</Paper>
					</Fade>
				)}
			</Popper>
		</div>
	);
}

export default ImagePopup;
