const parseYAxis = (yaxis) => {
  if (Array.isArray(yaxis)) {
    return yaxis;
  }
  return {
    ...yaxis,
    title: {
      text: yaxis.title,
    },
    labels: {
      format:
        `${yaxis?.labels?.preUnit || ""}` +
        " {text} " +
        `${yaxis?.labels?.unit || ""}`,
    },
  };
};
export const ColumnChartOptions = (props) => {
  const type = props?.horizontalBarView
    ? "bar"
    : props.chartType === "column-percent"
    ? "column"
    : "bar";

  return {
    chart: {
      type,
      height: props.height,
    },
    title: {
      text: props.title,
    },
    xAxis: {
      categories: props.xaxis.categories,
      title: {
        text: props.xaxis?.title || "",
      },
    },
    yAxis: {
      title: {
        text: props.yaxis?.title || "Values",
      },
      labels: {
        enabled: true,
        format:
          `${props?.yaxis?.preUnit || ""}` +
          " {text} " +
          `${props?.yaxis?.unit || ""}`,
      },
    },
    plotOptions: {
      series: {
        stacking: props.stacking, // Ensure stacking is set
      },
      //Changes for the custom colors -start
      column: {
        colorByPoint: props?.customColors?.show || false,
      },
    },
    colors: props?.customColors?.colors,
    //Changes for the custom colors -end

    //Extra part for a label on xAxis
    legend: {
      enabled: props?.showLegend || false,
    },

    series: props.data,
  };
};

export const scatterChartOptions = (props) => {
  return {
    chart: {
      type: "scatter",
      zoomType: "xy",
      height: props.height,
    },
    title: {
      text: props.title,
    },
    // subtitle: {
    //     text: 'Source: Heinz  2003'
    // },
    xAxis: {
      title: {
        enabled: true,
        text: props.xaxis.title,
      },
      startOnTick: true,
      endOnTick: true,
      showLastLabel: true,
      categories: props.xaxis.categories,
      labels: {
        format:
          `${props?.xaxis?.labels?.preUnit || ""}` +
          " {text} " +
          `${props?.xaxis?.labels?.unit || ""}`,
      },
    },
    yAxis: parseYAxis(props.yaxis),
    legend: props?.customLegend
      ? {
          ...props.customLegend,
        }
      : {
          enabled: props.showLegend || true,
          layout: "vertical",
          align: "left",
          verticalAlign: "top",
          x: 100,
          y: 70,
          floating: true,
          borderWidth: 1,
        },
    plotOptions: {
      scatter: {
        marker: {
          radius: 5,
          states: {
            hover: {
              enabled: true,
              lineColor: "rgb(100,100,100)",
            },
          },
        },
        // states: {
        //     hover: {
        //         marker: {
        //             enabled: false
        //         }
        //     }
        // },
        tooltip: props?.plotOptions?.scatter?.tooltip || {
          headerFormat: "<b>{series.name}</b><br>",
          pointFormat: props.noUnitsOnTooltip
            ? "{point.x}, {point.y}"
            : "{point.x} cm, {point.y} kg",
        },
      },
    },
    series: props.data,
  };
};

export const bubbleChartOptions = (props) => {
  return {
    chart: {
      type: "bubble",
      height: props.height,
      plotBorderWidth: 1,
      zoomType: "xy",
    },

    legend: {
      enabled: props.legendEnabled,
    },

    title: {
      text: props.title,
    },

    xAxis: {
      gridLineWidth: 1,
      title: {
        text: props.xaxis.title,
      },
      labels: {
        format: "{value}M",
      },
      plotLines: [
        {
          color: "black",
          dashStyle: "dot",
          width: 2,
          value: 0.65,
          zIndex: 3,
        },
      ],
      accessibility: {
        rangeDescription: "Range: 60 to 100 grams.",
      },
    },

    yAxis: {
      startOnTick: false,
      endOnTick: false,
      title: {
        text: props.yaxis.title,
      },
      labels: {
        format: "{value}M",
      },
      maxPadding: 0.2,
      plotLines: [
        {
          color: "black",
          dashStyle: "dot",
          width: 2,
          value: 1.35,
          zIndex: 3,
        },
      ],
      accessibility: {
        rangeDescription: "Range: 0 to 160 grams.",
      },
    },

    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
          format: "{point.name}",
        },
      },
    },

    series: [
      {
        data: props.data,
        colorByPoint: true,
      },
    ],
  };
};
