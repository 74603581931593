export const circularChartOptions = (props) => {

  return {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
    },
    title: {
      text: props.title,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      formatter: function () {
        return this.y + "%";
      },
    },
    legend: props?.legend || {},
    labels: props.labels,
    plotOptions: {
      pie: {
        colors: props.color,
        dataLabels: {
          enabled: true,
          formatter: function () {
            return this.y + "%";
          },
          distance: -30,
          style: {
            fontWeight: "bold",
            color: "black",
          },
        },
        allowPointSelect: false,
        showInLegend: true,
      },
    },
    series: props.data,
  };
};
