import React from "react";
import MidComp from "./MidComp";

function LanguageTree({ data }) {
	console.log("Gross data -------->", data);
	return (
		<div style={{ backgroundColor: "transparent !important" }}>
			{data.map((rootNode) => (
				<div key={rootNode?.id}>
					<MidComp data={rootNode} />
				</div>
			))}
		</div>
	);
}

export default React.memo(LanguageTree);
