import React, { useEffect, useState, lazy, Suspense } from "react";
import { Grid, Skeleton } from "@mui/material";
import {
  fetchConfigData,
  fetchDefaultTableData,
  setPageConfigData,
} from "./action";
import ComponentResolver from "./Resolver/ComponentResolver";
import "../MainCss.scss";
import _ from "lodash";
import { addNotifications } from "./data";
import { useDispatch } from "react-redux";
import { getStorage } from "../../utils/storage";

import LoadingOverlay1 from "../../componentsV1/LoadingOverlay";
import LoadingOverlay2 from "../../componentsV2/LoadingOverlay";

const Resolver = lazy(() => import("./Resolver/V3Resolver"));

const Index = ({ pageConfig }) => {
  const version = getStorage("APP_VERSION");
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [currentPageObj, setCurrentPageObj] = useState({});
  const appVersion = pageConfig?.version;
  const getConfigData = async () => {
    setLoader(true);
    const pageId = pageConfig?.json_id;
    const apidata = await fetchConfigData(pageId);

    if (pageConfig?.json_id) {
      dispatch(setPageConfigData(pageConfig));
    }
    if (
      apidata["notifications"] &&
      Object.keys(apidata["notifications"]).length > 0
    ) {
      addNotifications(apidata["notifications"]);
    }
    if (apidata["defaultApiKey"] && apidata["defaultApiKey"].length > 0) {
      await dispatch(
        fetchDefaultTableData({ model_names: apidata["defaultApiKey"] })
      );
    }
    setCurrentPageObj(apidata);
    setLoader(false);
  };

  useEffect(() => {
    setCurrentPageObj({});
    getConfigData();
  }, [pageConfig]);

  let productThem = "impact-plan-smart-them";

  if (version.length && version[0]?.status === "TRUE") {
    return (
      <LoadingOverlay1
        loader={loader}
        className={productThem + " impact-loading-overlay"}
      >
        <Grid container spacing={2} className="impact-home-container">
          <Grid
            item
            xs={12}
            className={productThem + " impact-home-subcontainer"}
          >
            {Object.keys(currentPageObj).length > 0 ? (
              <>
                <ComponentResolver
                  pageObject={currentPageObj}
                  redirectFrom="Home"
                />
              </>
            ) : null}
          </Grid>
        </Grid>
      </LoadingOverlay1>
    );
  } else {
    return (
      <LoadingOverlay2
        loader={loader}
        className={productThem + " impact-loading-overlay"}
      >
        <Grid container spacing={2} className="impact-home-container">
          <Grid
            item
            xs={12}
            className={productThem + " impact-home-subcontainer"}
          >
            {Object.keys(currentPageObj).length > 0 ? (
              <>
                <Suspense
                  fallback={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Skeleton
                        sx={{ bgcolor: "grey.100" }}
                        variant="rectangular"
                        width={"100%"}
                        height={100}
                      />
                    </div>
                  }
                >
                  {appVersion === "v3" ? (
                    <Resolver {...currentPageObj} />
                  ) : (
                    <ComponentResolver
                      pageObject={currentPageObj}
                      redirectFrom="Home"
                    />
                  )}
                </Suspense>
              </>
            ) : null}
          </Grid>
        </Grid>
      </LoadingOverlay2>
    );
  }
};

export default Index;
