import React from "react";
import { Modal } from "impact-ui";

export default function ModalContainer({
	size = "small",
	heading = "Modal Heading",
	isOpen,
	setIsOpen,
	primarybtnText,
	primarybtnAction,
	children,
	showPrimaryButton,
	showTertiaryButton,
	isPortal,
}) {
	return (
		<Modal
			size={size}
			heading={heading}
			isOpen={isOpen}
			onClose={() => setIsOpen(false)}
			isPortal={true}
			primaryButtonProps={
				showPrimaryButton
					? {
							children: primarybtnText,
							onClick: () => primarybtnAction(),
					  }
					: null
			}
			tertiaryButtonProps={
				showTertiaryButton
					? {
							children: "Cancel",
							onClick: () => setIsOpen(false),
					  }
					: null
			}>
			{children}
		</Modal>
	);
}
