import {
  getApiCall,
  getClientAndProductNames,
  postApiCall,
} from "../../map/action";
import { groupBy, mapValues } from "lodash";
import { toast } from "react-toastify";
import { setStorage } from "../../utils/storage";

export const fetchConfigData = (filterProperty, reqObj) => {
  let url = encodeURIComponent(filterProperty);
  return postApiCall(`get_page_json?pageid=${url}`, { pageid: url })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const fetchHierarchyData = async (filterProperty, reqObj) => {
  let url = encodeURIComponent(filterProperty);
  return postApiCall("get_data_model", { model_name: url })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const getMultipleModelsData = (reqParams) => {
  return postApiCall("get_mutliple_data_models", reqParams)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { err };
    });
};

export const getDataModelWithFilter = async (reqParams) => {
  return postApiCall("get_data_model_with_filter", reqParams)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const fetchFilterDataAction = (reqParams) => (dispatch) => {
  return postApiCall("get_mutliple_data_models", reqParams)
    .then((res) => {
      const { tb_filter_master_copy, tb_filter_master_values } = res.data;
      dispatch({
        type: "UPDATE_FILTER_DATA",
        payload: {
          tb_filter_master_copy,
          tb_filter_master_values,
        },
      });
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
export const fetchFormDataAction = (reqParams) => (dispatch) => {
  return postApiCall("get_mutliple_data_models", reqParams)
    .then((res) => {
      const { tb_form_master, tb_form_master_values, table_config } = res.data;

      // const groupByTableConfig = mapValues(groupBy(table_config, 'table_name'))
      dispatch({
        type: "UPDATE_FORM_DATA",
        payload: {
          tb_form_master,
          tb_form_master_values,
          // table_config:groupByTableConfig
        },
      });
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const fetchData = (url, reqParams) => {
  return postApiCall(url, reqParams)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
export const deleteData = (url, reqParams) => {
  return postApiCall(url, reqParams)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const fetchDefaultTableData = (reqParams) => (dispatch) => {
  return postApiCall("get_mutliple_data_models", reqParams)
    .then((res) => {
      let params = {};
      reqParams.model_names.forEach((keyName) => {
        params[`${keyName}`] = res.data[`${keyName}`];
        params[`${keyName}_copy`] = res.data[`${keyName}`];
      });
      dispatch({
        type: "TABLE_DATA",
        payload: { ...params },
      });
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const setPageConfigData = (reqParams) => (dispatch) => {
  dispatch({
    type: "SET_PAGE_CONFIG_DATA",
    payload: reqParams,
  });
};

export const setTableConfigAction = (reqParams) => (dispatch) => {
  return postApiCall("get_mutliple_data_models", reqParams)
    .then((res) => {
      const { table_config } = res.data;

      const groupByTableConfig = mapValues(groupBy(table_config, "table_name"));
      dispatch({
        type: "UPDATE_TABLE_CONFIG",
        payload: {
          table_config: groupByTableConfig,
        },
      });
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const refreshUserSessionAction = (navigate, reqParams) => (dispatch) => {
  const { product_name, client_name } = getClientAndProductNames();
  if (product_name && client_name) {
    return postApiCall("refresh_user_session_on_app_start", reqParams)
      .then((res) => {
        if (res && (res?.data?.success || res.detail?.success)) {
          dispatch({
            type: "USER_SESSION_UPDATED",
            payload: { userSession: true, isRedirectRequired: false },
          });
        } else {
          toast.error(`${res.detail?.message}`);
          // setTimeout(() => {
          //   window.location.href = "/not-found"
          // }, 2000);
          //
        }
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
};

// this function get the app version
export const getAppVersion = (reqParams) => {
  return postApiCall(`get_mutliple_data_models`, reqParams)
    .then((res) => {
      const { tb_version } = res.data;
      setStorage(
        "APP_VERSION",
        tb_version
          ? tb_version
          : [
              { version: "v1", status: "TRUE" },
              { version: "v2", status: "FALSE" },
            ]
      );
      return tb_version;
    })
    .catch((err) => {
      return err;
    });
};

export const updateModelData = (reqParams) => {
  return postApiCall("update_data_model", reqParams)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return { err };
    });
};
