import { useEffect, useState } from "react";
import useAction from "../../../utils/useAction";
import { Switch } from "impact-ui";
// Material Ui
import { FormControlLabel, Typography } from "@mui/material";

export default function CustomSwitch({ data }) {
  const [check, setcheck] = useState(data.defaultCheck);
  const invokeAction = useAction();
  useEffect(() => {
    if (data.switchAction) {
      check
        ? invokeAction(data.switchAction?.[0])
        : invokeAction(data?.switchAction?.[1]);
    }
  }, [check]);
  return (
    <div
      className="custom-switch"
      style={{ display: "flex", gap: "8px", alignItems: "center" }}
    >
      <span
        style={{
          fontSize: "12px",
          color: "#758490",
          lineHeight: "18px",
          marginRight: "10px",
        }}
      >
        {data.label}{" "}
      </span>
      <FormControlLabel
        style={data.style}
        control={
          <Switch
            id="switch"
            onChange={(e) => setcheck(!check)}
            defaultChecked={data.defaultCheck}
          />
        }
        label={
          <Typography
            sx={{
              fontSize: "12px",
              color: "#758490",
              lineHeight: "18px",
              marginLeft: "5px",
            }}
          >
            {data.endLabel}
          </Typography>
        }
      />
    </div>
  );
}
