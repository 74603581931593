import React, { useState } from "react";
//import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { makeStyles, styled } from "@mui/styles";
import moment from "moment";
import "./DatePicker.scss";
import CalendarMonthOutlined from "@mui/icons-material/CalendarMonthOutlined";
import { Paper } from "@mui/material";
import { width } from "@mui/system";
import Text from "../../utils/Text";

// Impact UI component
import { DateRangePicker } from "impact-ui";

const styles = (theme) => ({
  tab: {
    border: "4px solid #dfeaf5",
  },
});

const useStyles = makeStyles(styles);

const Item = styled(Paper)(({ theme, width, labeldirection }) => ({
  ...theme.typography.body2,
  display: labeldirection === "row" && "flex",
  alignItems: labeldirection === "row" && "center",
  justifyContent: labeldirection === "row" && "space-between",
  textAlign: "left",
  boxShadow: "none",
  background: "transparent",
  // width,
  color: theme.palette.text.secondary,
}));

const CustomDateRangePicker = (props) => {
  const classes = useStyles();
  const {
    label,
    labeldirection,
    values,
    width,
    fieldWidth,
    isMandatory,
    onChange,
    setValues,
  } = props;
  var date = moment();

  var currentDate = date.format("YYYY/MM/DD");
  const [startDate, setStartDate] = useState(
    moment(new Date(values?.[0] || currentDate))
  );
  const [endDate, setEndDate] = useState(
    moment(new Date(values?.[1] || currentDate))
  );

  return (
    // <Item
    //   className='filterGroup'
    //   labeldirection={labeldirection}
    //   width={width}
    // >
    //   <Text
    //     style={{
    //       marginRight: "10px",
    //     fontSize: "12px",
    //     color: "#758490",
    //     }}
    //   >
    //     {label}
    //     {isMandatory ? <span style={{ color: 'red' }}> * </span> : null}
    //   </Text>
    <div
      style={{
        width: "inherit",
        height: "100%",
        boxSizing: "content-box",
        display: "flex",
        alignItems: "center",
        gap: "8px",
        ...props.style,
      }}
    >
      {/* <DateRangePicker
            style={{height: "35px"}}
            onChange={onChange}
            value={values}
            clearIcon={null}
            dayPlaceholder={'dd'}
            monthPlaceholder={'mm'}
            yearPlaceholder={'yyyy'}
            calendarIcon={
              <CalendarMonthOutlined
                style={{ fontSize: 20, color: '#515151' }}
              />
            }
          /> */}
      {labeldirection === "row" ? (
        <>
          <span
            style={{ color: "#758490", fontSize: "12px", lineHeight: "18px" }}
          >
            {label}{" "}
            {isMandatory ? <span style={{ color: "red" }}> * </span> : null}
          </span>
          <DateRangePicker
            numberOfMonths={2}
            showRangeSelector
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            startDateInputProps={{ label: "StartDate", name: "startDate" }}
            endDateInputProps={{ label: "EndDate", name: "endDate" }}
            primaryButtonProps={{
              children: "Apply",
              onClick: () => onChange([startDate, endDate]),
            }}
          />
        </>
      ) : (
        <DateRangePicker
          label={
            <>
              {label}{" "}
              {isMandatory ? <span style={{ color: "red" }}> * </span> : null}
            </>
          }
          numberOfMonths={2}
          showRangeSelector
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          startDateInputProps={{ label: "StartDate", name: "startDate" }}
          endDateInputProps={{ label: "EndDate", name: "endDate" }}
          primaryButtonProps={{
            children: "Apply",
            onClick: () => onChange([startDate, endDate]),
          }}
        />
      )}
    </div>
    //</Item>
  );
};

export default CustomDateRangePicker;
