import { Checkbox, Typography, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import CircleIcon from '@mui/icons-material/Circle';
import useAction from "../../../utils/useAction";
const useStyles = makeStyles({
  block: {
    //margin: 1.5,
    //padding: 2,
  },
  red: {
    borderColor: "#DE3740",
    backgroundColor: "#FCE9EA",
    color: "#DE3740",
    boxSizing: "content-box",
    marginBottom: "2px",
  },
  green: {
    borderColor: "#40AD5F",
    backgroundColor: "#E8F5EC",
    color: "#40AD5F",
    boxSizing: "content-box",
    marginBottom: "2px",
  },
  orange: {
    borderColor: "#FF832B",
    backgroundColor: "#FFD9BF",
    color: "#FF832B",
    boxSizing: "content-box",
    marginBottom: "2px",
  },
  grey: {
    backgroundColor: "#808080",
    color: "#FEF6EB",
    boxSizing: "content-box",
    marginBottom: "2px",
  },
  aqua: {
    backgroundColor: "#34BFA3",
    color: "#FEF6EB",
    boxSizing: "content-box",
    marginBottom: "2px",
  },
  lightblue: {
    backgroundColor: "#B1BEFF",
    color: "#FEF6EB",
    boxSizing: "content-box",
    marginBottom: "2px",
  },
  blue: {
    backgroundColor: "#7CB5EC",
    color: "#FEF6EB",
    boxSizing: "content-box",
    marginBottom: "2px",
  },
  lightred: {
    backgroundColor: "#EF5D5D",
    color: "#FEF6EB",
    boxSizing: "content-box",
    marginBottom: "2px",
  },
  lightgreen: {
    backgroundColor: "#B1DDC4",
    boxSizing: "content-box",
    marginBottom: "2px",
  },
  brickred: {
    backgroundColor: "#F67777",
    color: "#FFFFFF",
    boxSizing: "content-box",
    marginBottom: "2px",
  },
  darkgreen: {
    backgroundColor: "#90CCB6",
    boxSizing: "content-box",
    marginBottom: "2px",
  },
  lime: {
    backgroundColor: "#EEF7EA",
    boxSizing: "content-box",
    marginBottom: "2px",
  },
  peach: {
    backgroundColor: "#F8CCCC",
    boxSizing: "content-box",
    marginBottom: "2px",
  },
  flexContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    boxSizing: "content-box",
  },
  bullet: {
    fontSize: 30,
  },
  legendItem: {
    display: "flex",
    gap: 2,
    alignItems: "center",
  },
  heading: {
    color: "#44677B",
    margin: 2,
    padding: 5,
    fontSize: 18,
    textAlign: "center",
  },
});

const BoxAligner = (props) => {
  switch (props) {
    case "left":
      return "flex-start";
    case "center":
      return "center";
    case "right":
      return "flex-end";
    default:
      return "center";
  }
};

const DataChart = (props) => {
  const { selectedCheckbox} = useSelector(
    (state) => state.home
  );
  const { info, legendPosition, legends, title, actionKey } = props.data;
  const classes = useStyles();
  const dispatch = useDispatch();
  const invokeAction = useAction()
  const [checkStatus, setCheckStatus] = useState(0);
  const handleCheckboxChange = (item, value) => {
    const data = {
      item: item,
      value: value,
    };
    dispatch({
      type: "UPDATE_SELECTED_CHECKBOX",
      payload: data,
    });
    if (actionKey) {
      setCheckStatus((prev) => (value ? prev + 1 : prev - 1));
    }
  };
  useEffect(() => {
    if (checkStatus === 0 || checkStatus === 1) {
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: { [actionKey]: checkStatus === 0 ? false : true },
      });
    }
  }, [checkStatus]);
  return (
    <>
      {/* CHART  */}
      {title && <Typography className={classes.heading}> {title}</Typography>}
      <Box className={classes.flexContainer} sx={{fontWeight: "bold",fontSize:"14px"}}>
        {info &&
          info.map((item) => {
            return (
              <>
                {item?.label ? (
                  <Tooltip
                    title={
                      <>
                        {item.tooltipText?.map((element) => (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <p style={{ ...element.keyStyle }}>{element.key}</p>
                            <p style={{ ...element.valueStyle }}>
                              {element.value}
                            </p>
                          </div>
                        ))}
                      </>
                    }
                    placement={item.placement || "top"}
                    arrow
                    PopperProps={{
                      sx: {
                        "& .MuiTooltip-tooltip": {
                          backgroundColor: "#eaf6f6",
                          color: "black",
                          fontSize: "12px",
                          border: "1px solid #dadde9",
                          display: item.tooltipRequired ? "block" : "none",
                        },
                        "& .MuiTooltip-arrow": {
                          color: "#eaf6f6",
                          display: item.tooltipRequired ? "block" : "none",
                        },
                      },
                    }}
                  >
                    <Box
                      className={
                        classes.block + " " + classes[`${item.colorType}`]
                      }
                      sx={{
                        width: item?.width,
                        height: item?.height || 100,
                        borderColor: item?.styles?.color,
                        color: item?.styles?.color,
                        background: item?.styles?.backgroundColor,
                        borderWidth: item?.styles?.borderSize || 1,
                        borderStyle: item?.styles?.borderType || "solid",
                        fontWeight: 500,
                        ...item.styles,
                      }}
                      value={item?.label}
                      onClick={(e) => {
                        invokeAction(item.action)
                        if (props?.data?.uniqueKey === "toggle-event") {
                          handleCheckboxChange(e.target.value, 1);
                        }
                      }}
                    >
                      {item?.hasCheckbox && (
                        <Checkbox
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: item.checkboxSize || 15,
                            },
                          }}
                          defaultChecked={item.defaultChecked || false}
                          value={item?.label}
                          onChange={(e) => {
                            handleCheckboxChange(
                              e.target.value,
                              e.target.checked
                            );
                          }}
                        />
                      )}
                      {item.label}
                    </Box>
                  </Tooltip>
                ) : (
                  <Box
                    className={classes.flexContainer}
                    sx={{ width: item?.rowWidth }}
                  >
                    {item.row.map((element) => {
                      return (
                        <>
                          <Tooltip
                            title={
                              <>
                                {element.tooltipText?.map((item) => (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "10px",
                                    }}
                                  >
                                    <p style={{ ...item.keyStyle }}>
                                      {item.key}
                                    </p>
                                    <p style={{ ...item.valueStyle }}>
                                      {item.value}
                                    </p>
                                  </div>
                                ))}
                              </>
                            }
                            placement={element.placement || "top"}
                            arrow
                            PopperProps={{
                              sx: {
                                "& .MuiTooltip-tooltip": {
                                  backgroundColor: "#eaf6f6",
                                  color: "black",
                                  fontSize: "12px",
                                  border: "1px solid #dadde9",
                                  display: element.tooltipRequired
                                    ? "block"
                                    : "none",
                                },
                                "& .MuiTooltip-arrow": {
                                  color: "#eaf6f6",
                                  display: element.tooltipRequired
                                    ? "block"
                                    : "none",
                                },
                              },
                            }}
                          >
                            <Box
                              className={
                                classes[`${element.colorType}`] +
                                " " +
                                classes.block
                              }
                              sx={
                                element.blockStyle
                                  ? element.blockStyle
                                  : {
                                      width: element?.width,
                                      height: element.height || 100,
                                      borderColor: element?.styles?.color,
                                      color: element?.styles?.color,
                                      background:
                                        element?.styles?.backgroundColor,
                                      borderWidth:
                                        element?.styles?.borderSize || 1,
                                      borderStyle:
                                        element?.styles?.borderType || "solid",
                                      fontWeight: 500,
                                    }
                              }
                              value={item?.label}
                              onClick={(e) => {
                                if (props?.data?.uniqueKey === "toggle-event") {
                                  handleCheckboxChange(e.target.value, 1);
                                }
                              }}
                            >
                              {element?.hasCheckbox && (
                                <Checkbox
                                  sx={{
                                    "& .MuiSvgIcon-root": {
                                      fontSize: element.checkboxSize || 15,
                                    },
                                  }}
                                  defaultChecked={
                                    element.defaultChecked || false
                                  }
                                  value={element?.label}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      e.target.value,
                                      e.target.checked
                                    );
                                  }}
                                />
                              )}
                              {element.label}
                            </Box>
                          </Tooltip>
                        </>
                      );
                    })}
                  </Box>
                )}
              </>
            );
          })}
      </Box>

      {/* LABEL LEGEND  */}
      {legends && (
        <Box
          sx={{
            margin: "16px 0",
            justifyContent: BoxAligner(legendPosition),
            display: "flex",
            paddingBottom: 1,
            gap: "2%",
          }}
        >
          {legends.map((item) => {
            return (
              <>
                <Box className={classes.legendItem}>
                  <CircleIcon
                    sx={{
                      color: item.color || "#40AD5F",
                      fontSize: item.bulletSize || 20,
                    }}
                  />
                  <Typography
                    sx={
                      item?.textStyle
                        ? item.textStyle
                        : {
                            color: item.color || "#40AD5F",
                            fontSize: item.fontSize || "0.875rem",
                          }
                    }
                  >
                    {item.text}
                  </Typography>
                </Box>
              </>
            );
          })}
        </Box>
      )}
    </>
  );
};

export default DataChart;
