import LoadingOverlay from 'react-loading-overlay-ts';
import React from 'react';

// Impact UI component
import { Spinner } from "impact-ui";



const LoadingOverlayWrapper = ({ loader, color, children }) => {
  if(loader){
    return <div className='loading-screen'><Spinner size="large" showLoadingText /></div>
  }else{
    return (
      <>
       {children}
      </>
    )
  }
};

export default LoadingOverlayWrapper;



// const LoadingOverlayWrapper = ({
//   loader,
//   color,
//   text,
//   position = 'fixed',
//   height = '100vh',
//   minHeight,
//   maxHeight,
//   background = '#fff',
// }) => {
//   return (
//     <LoadingOverlay
//       active={loader}
//       spinner
//       text={text || 'Loading...'}
//       styles={{
//         overlay: (base) => ({
//           ...base,
//           background,
//           color: 'black',
//           zIndex: 3,
//           borderRadius: '0px',
//           width: '100%',
//           height: !minHeight && !maxHeight && height,
//           minHeight,
//           maxHeight,
//           position: position,
//         }),
//         spinner: (base) => ({
//           ...base,
//           width: '50px',
//           '& svg circle': {
//             stroke: color ? color : '#44677b',
//           },
//           zIndex: 999,
//         }),
//         content: (base) => ({
//           ...base,
//           color: color ? color : '#44677b',
//         }),
//       }}
//     />
//   );
// };

// export default LoadingOverlayWrapper;
