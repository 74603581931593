import useAction from "../../../utils/useAction";
export const BarChartOptions = (props) => {
  const invokeAction = useAction();

  const scrollablePlotAreaMinWidth =
    props?.xaxis?.categories?.length > 6
      ? (props?.xaxis?.categories?.length / 7) * 850
      : 0;

      const addActionsInData = (data) => {
        const initialData = [...data];
        const dataWithActions = initialData?.map((data) => {
            return data?.action
                ? {
                      ...data,
                      events: {
                          click: function () {
                              invokeAction(data?.action);
                          }, 
                      },
                  }
                : { ...data };
        });
        return dataWithActions;
    };  
  return {
    chart: {
      type: "column",
      height: props.height,
      spacing: [20, 10, 5, 5],
      backgroundColor: props.hideBg ? null : "#F5F8FA",
      plotBackgroundColor: "#ffffff",
      ignoreHiddenSeries: false,
      scrollablePlotArea:
        props.disableScroll === true
          ? null
          : {
              minWidth: scrollablePlotAreaMinWidth,
              scrollPositionX: 0,
            },
    },
    title: {
      text: props.title,
    },
    yAxis: {
      title: {
        text: props.yaxis.title,
        style: {
          color: "#44677b",
          fontSize: "0.7rem",
          fontWeight: "600",
        },
      },
      labels: {
        style: {
          color: "#44677b",
          fontSize: "0.6rem",
        },
        rotation: 0, 
      },
      tickAmount: props.yaxis.tickAmount,
      gridLineColor: "#e9e9e9",
      categories: props.yaxis.categories,
      tickInterval: props.yaxis.tickInterval,
      tickPositions: props?.yaxis?.tickPositions || undefined,
    },
    xAxis: {
      title: {
        text: props.xaxis.title,
        style: {
          color: "#44677b",
          fontSize: "0.7rem",
          fontWeight: "600"
        },
      },
      accessibility: {
        rangeDescription: props.xaxis.title,
      },
      labels: {
        style: {
          color: "#44677b",
          fontSize: "0.6rem",
        },
      },
      categories: props.xaxis.categories,
      plotLines: props.xaxis.plotLines,
    },
    legend: {
      enabled: props.showLegend,
      layout: "horizontal",
      itemStyle: {
        color: "#44677b",
        fontSize: "0.7rem",
        fontWeight: "600",
      },
      itemCheckboxStyle: {
        position: "absolute",
        width: "12px",
        height: "12px",
      },
      padding: 0,
      itemMarginTop: 4,
      itemMarginBottom: 2,
      ...props.legend
    },
    tooltip: {
      format: "<span></span>",
    },
    plotOptions: {
      series: {
        pointWidth: props.xaxis.columnWidth,
        columnWidth: 40,
        marker: {
          enabled: false,
        },
        states: {
          hover: {
            enabled: false,
          },
        },
        lineWidth: 1.5,
      },
      column: {
        stacking: props.stacking || "",
      },
    },
    series: props.actionOnBars ? addActionsInData(props.data) : props.data,
    credits: {
      enabled: false,
    },
  };
};
