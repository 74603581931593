import { Tooltip } from "impact-ui";
import { InfoOutlined } from "@mui/icons-material";
import { SparkLineChart } from "@mui/x-charts/SparkLineChart";
import "./mondayKpiStyle.css";
import { useEffect, useState, useMemo } from "react";
import ScrollContainer from "./ScrollContainer";
import SouthOutlinedIcon from "@mui/icons-material/SouthOutlined";
import NorthOutlinedIcon from "@mui/icons-material/NorthOutlined";
import { getMultipleModelsData } from "../../action";
import CustomGridContainer from "./GridContainer";
import { useSelector } from "react-redux";
const settings = {
  showTooltip: true,
  showHighlight: true,
  height: 40,
};
export default function MondayKPICards({ data }) {
  const { apikey } = data;
  const [cardsData, setCardsData] = useState([]);
  const { dependentInfo } = useSelector((state) => state.home);
  const expand = dependentInfo[data["expandDependentKey"]] || false;
  useEffect(async () => {
    const res = await getMultipleModelsData({ model_names: [apikey] });
    setCardsData(res[apikey]);
  }, [apikey]);
  const mondayCards = useMemo(() => {
    return cardsData.map((item) => {
      return (
        <div className="monday-kpi-card">
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <p className="monday-kpi-card-header">{item.header}</p>
            <Tooltip
              text={item.tooltip}
              disabled={false}
              placement={"bottom"}
              isPortal={true}
              zIndex={5}
            >
              <InfoOutlined sx={{ width: "16px", color: "#808894" }} />
            </Tooltip>
          </div>
          <p className="monday-kpi-card-header-value">{item.header_value}</p>
          {renderContent(item.content||"")}
          <div style={{ flexGrow: 1 }}>
            <SparkLineChart
              plotType={"line"}
              data={getChartLineData(item.chart_line||"")}
              colors={["grey"]}
              sx={{
                "& .MuiLineElement-root": {
                  strokeWidth: 1,
                },
              }}
              {...settings}
            />
          </div>
          <p
            style={{
              fontSize: "10px",
              lineHeight: "15px",
              fontWeight: 400,
              color: "#394960",
              marginTop: "10px",
            }}
          >
            {item.footer}
          </p>
        </div>
      );
    });
  }, [cardsData]);

  return (
    <div style={{}}>
      {expand ? (
        <CustomGridContainer>
          <>{mondayCards}</>
        </CustomGridContainer>
      ) : (
        <ScrollContainer>
          <>{mondayCards}</>
        </ScrollContainer>
      )}
    </div>
  );
}
function renderContent(contentStr = "") {
  const content = contentStr.split("|");
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "10px",
      }}
    >
      {content.map((item) => {
        const dataSet = item.split(",");
        return (
          <div>
            <p
              style={{
                fontSize: "11px",
                lineHeight: "16.5px",
                letterSpacing: "1%",
                fontWeight: 400,
                color: "#808894",
              }}
            >
              {dataSet[0]}
            </p>
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <p
                style={{
                  fontSize: "14px",
                  lineHeight: "21px",
                  letterSpacing: "1%",
                  fontWeight: 500,
                  color: dataSet[2],
                }}
              >
                {dataSet[1]}
              </p>
              {dataSet[3] && (
                <div style={{ color: dataSet[2] }}>
                  {dataSet[3] === "up" ? (
                    <NorthOutlinedIcon />
                  ) : (
                    <SouthOutlinedIcon />
                  )}
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}
function getChartLineData(str) {
  return str.split(",").map((v) => Number(v));
}
